import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Build or Buy Virtual Agnet – Ultimate comparison"
        description="Wondering if you should Build vs Buy a Virtual agent for your organization? We have you covered with indepth analysis."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Build or buy Virtual Agent
                          </h1>
                        </div>
                      </div>
                      <div className="market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Customer satisfaction is the holy grail for most
                          businesses. Even if you crack every other aspect of
                          your business, right from building a superior product
                          to automation at the right touchpoints, if you fail in
                          this area, nothing else matters. Delivering a superior
                          customer experience can be the game-changer in every
                          industry that you can possibly imagine. Offering a
                          stellar service can also get ridiculously expensive.
                          Imagine having an army of agents working in shifts to
                          take care of your company’s support needs.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          At this juncture, the use of an AI-driven virtual
                          agent (or chatbot) becomes crucial. Available at a
                          fraction of the cost of a human agent, virtual agents
                          can scale as much as possible and are available 24*7
                          too. According to a survey by MIT Technology Review,
                          <a href="https://www.technologyreview.com/2018/11/14/239924/humans-bots-tension-and-opportunity/">
                            {" "}
                            90% of businesses reported huge improvements
                          </a>{" "}
                          in complaint resolution after they started using
                          chatbots.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Here comes an interesting question.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          It is possible to build chatbots from scratch, and
                          there are SaaS companies that offer a fully-functional
                          chatbot too. What do you choose?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To build or not to?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this article, we are going to discuss whether to
                          build or buy a virtual agent for your business.
                        </p>
                        <h2 class="font-section-normal-text-medium">
                          Considerations for building vs buying a virtual agent:{" "}
                        </h2>
                        <p class="font-section-normal-text">
                          Let us look at a number of points that should be
                          considered when pondering whether to build or buy a
                          chatbot.{" "}
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          1. Project Vs Product (The No-code approach) Mindset:
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          Businesses buy software off the shelf because they
                          don’t have to go through the onerous process of
                          building one from scratch. Building software comes
                          with a host of problems.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you want to build a product, you need to hire
                          experts of different capabilities.
                        </p>
                        <ol
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            You want someone from the UI/UX team to ensure that
                            the app is accessible to everyone and is pleasant to
                            navigate.{" "}
                          </li>
                          <li>
                            You need developers who will build the back-end and
                            front-end of the software.{" "}
                          </li>
                          <li>
                            Testers to ensure that the final product is smooth
                            and devoid of bugs.{" "}
                          </li>
                          <li>
                            A product manager to take care of all of this, and
                            many more experts.{" "}
                          </li>
                        </ol>
                        <p class="font-section-normal-text">
                          But if you are buying a finished product from a
                          vendor, you don’t have to post an ad on Monster.com
                          and go on a hiring spree. It is as easy as buying a
                          product, you will gain access to the software
                          immediately.{" "}
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          2. Finding skilled developers:
                        </h5>
                        <p class="font-section-normal-text">
                          The issue isn’t only about hiring developers who are
                          capable of creating a powerful virtual agent. Do they
                          have the right mindset to pull off a project of this
                          size? Do they have the right set of skills to build a
                          virtual agent? Hiring skilled developers is also a
                          difficult task.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          3. Innovations and Features:
                        </h5>
                        <p class="font-section-normal-text">
                          Let us consider a few functionalities and features of
                          a virtual agent and understand how different will be
                          your journey if you take the product approach from the
                          project approach.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          a. Advanced NLU to power virtual agent:
                        </h5>
                        <p class="font-section-normal-text">
                          If you end up building a virtual agent that can handle
                          20 use cases, you also need to manage these
                          conversations. The virtual agent should keep improving
                          itself with each conversation and with the passage of
                          time. The project approach requires you to work on
                          improving the virtual agent’s understanding of the
                          conversations that happen in the niche. It is going to
                          take you time to add any innovation, no matter how
                          small it is going to be. You also need to worry about
                          the time factor.
                        </p>
                        <p class="font-section-normal-text">
                          If you buy the virtual agent platform directly from a
                          vendor, the onus is on them to add innovative features
                          to their product. They will work on releasing new
                          updates to the product to make it as attractive as
                          possible to their potential customers.
                        </p>

                        <h5 className="font-section-sub-header-small-bold">
                          b. Omnichannel:
                        </h5>
                        <p class="font-section-normal-text">
                          When you build the virtual agent from the ground up,
                          you would need to facilitate omnichannel conversations
                          by yourself. It will require the effort of more
                          developers, and that will increase the costs.{" "}
                        </p>
                        <p class="font-section-normal-text">
                          If you were to buy a virtual agent from a vendor, you
                          do not have to worry whether omnichannel conversations
                          are possible. It is almost a given these days and they
                          come in-built with the software.{" "}
                        </p>
                        <h5 className="font-section-sub-header-small-bold ">
                          c. App Integrations & App Workflows:
                        </h5>
                        <p class="font-section-normal-text">
                          A virtual agent isn’t an end in itself. It is an
                          important cog in the wheel of your customer-facing
                          operations. For your AI virtual agent to be highly
                          effective, it needs to facilitate multiple touchpoints
                          that will impact customer experiences. You need to
                          integrate the virtual agent with a number of systems,
                          such as CRM, inventory management, scheduling, email
                          marketing, etc.
                        </p>
                        <p class="font-section-normal-text">
                          <a href="https://userlike.com/en/blog/consumer-chatbot-perceptions">
                            {" "}
                            68% of customers
                          </a>{" "}
                          like chatbots as they provide quick solutions, so it
                          needs to be an all-encompassing solution that meets
                          their expectations every single time. For virtual
                          agents to be highly effective, they should have data
                          analytics and continuous monitoring to calculate the
                          effectiveness of integration touchpoints.
                        </p>
                        <p class="font-section-normal-text">
                          If you buy a virtual agent, they will give you
                          ready-made integrations with a number of information
                          systems and standardized interfaces for integration
                          with custom applications. On the other hand, if you
                          are creating your own chatbot, then you need to build
                          integration capabilities from scratch.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          d. Multi-lingual:
                        </h5>
                        <p class="font-section-normal-text">
                          For those businesses who buy the chatbot from a
                          vendor, they can safely assume that it will have
                          multilingual capabilities. It is one less thing to
                          worry about. But for businesses that are particular
                          about building one from scratch, it is imperative that
                          they work on this as well.
                        </p>
                        <h5 className="font-section-sub-header-small-bold ">
                          e.Dialog management:
                        </h5>
                        <p class="font-section-normal-text">
                          The ability of a virtual agent to engage in an
                          interactive dialogue with a human being on a
                          particular topic is pivotal if the chatbot has to be a
                          success. Most businesses that build virtual agents
                          from scratch do not leverage machine learning
                          technology and cannot self-learn, nor does it
                          understand true intent.
                        </p>
                        <p class="font-section-normal-text">
                          Buying a chatbot from a SaaS vendor will not have this
                          problem as they consider all of this into their
                          product. It would have handled hundreds of different
                          requests from customers, and trained across millions
                          of interactions.{" "}
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          4. Cost / Affordability:
                        </h5>
                        <p class="font-section-normal-text">
                          If you are taking the project route, then the business
                          should invest in procuring development tools,
                          cloud-hosting environment, software licensing, storage
                          infrastructure and pay maintenance fees to manage
                          these tools. All of this will break your bank.
                        </p>
                        <p class="font-section-normal-text">
                          When you are buying the virtual agent directly from a
                          vendor, the cost is going to be nominal as there are
                          SaaS products that lets you pay a nominal price
                          monthly. There is no fee for hosting as everything is
                          included in it.
                        </p>
                        <p class="font-section-normal-text">
                          The vendors will distribute the cost of maintenance
                          and operations to their clients, this is where
                          economies of scale kick in. That’s why the charges
                          will be less when compared to building it by yourself.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          5. Easy of deployment:
                        </h5>
                        <p class="font-section-normal-text">
                          Deploying a virtual agent on your private hosting
                          platform is going to be a difficult and cumbersome
                          process, given how feature-heavy the end product will
                          be.
                        </p>
                        <p class="font-section-normal-text">
                          When it is a SaaS product, there is no installation or
                          equipment updates or traditional licensing management
                          required. Deploying the software is easy-peasy, and
                          there will be zero downtime too.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          6. Ease of Maintenance:
                        </h5>
                        <p class="font-section-normal-text">
                          In the case of virtual agents, maintenance also
                          implies maintaining the conversations. If you buy the
                          virtual agent from a SaaS company, they will take care
                          of it. You do not have to worry about maintaining the
                          product at all.
                        </p>
                        <p class="font-section-normal-text">
                          If you take the project approach, then you have to
                          maintain the code, the project, and the conversations.
                          It won’t be easy, developers will find it incredibly
                          complex to maintain it. If there is any change in the
                          environment, then the team should go back to the
                          drawing board and constantly monitor to see if the
                          product is working properly.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          7. Need for data for continuous training:
                        </h5>
                        <p class="font-section-normal-text">
                          For a virtual agent to be extremely powerful, it needs
                          to be trained. When you buy a chatbot, the SaaS vendor
                          themselves train the bot by making it ‘read’ a number
                          of conversations. It will have all the necessary
                          features that train the bot automatically without any
                          external help. Since these businesses have
                          conversations from a number of businesses, they would
                          also be following the best practices to give you the
                          bot pre-trained.
                        </p>
                        <p class="font-section-normal-text">
                          If you were to choose Workativ, we have a marketplace
                          where we offer prebuilt conversations where you can
                          make edits. You can add or delete the conversations
                          based on what you want, to suit your business goals.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          8. Core competencies:
                        </h5>
                        <p class="font-section-normal-text">
                          If your core competency is not building virtual agent
                          chatbots, then buying them from a vendor is the most
                          sensible thing to do. Otherwise, you need to allocate
                          extra resources on it and do a bunch of other
                          activities too. It might also involve making your
                          employees move away from doing their most meaningful
                          work.
                        </p>
                        <p class="font-section-normal-text">
                          Imagine this, you are a new-age startup that is
                          building software to connect specialized doctors and
                          patients. Your developers were excited to be a part of
                          an organization whose product will help people. If you
                          separate them from this vision and ask them to build a
                          virtual agent, it might seriously affect their morale,
                          and productivity.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          9. Time-to-market vs Time-to-value:
                        </h5>
                        <p class="font-section-normal-text">
                          If you were to build a virtual agent from scratch, the
                          time-to-market will be at least a minimum of 6 months,
                          depending on the number of people involved in the
                          project. For businesses that want an immediate chatbot
                          solution, going the product route by buying it from a
                          company is the best option.
                        </p>
                        <p class="font-section-normal-text">
                          Apart from not realising your time-to-value
                          immediately when you build it from scratch, there are
                          a number of other considerations too that we have
                          discussed in this article:
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          1. The need to hire{" "}
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          2. Costs involved, both to build the product and
                          maintain it{" "}
                        </p>
                        <p class="font-section-normal-text">
                          3. Lack of data to train the bot, and so on.{" "}
                        </p>
                        <p class="font-section-normal-text">
                          One more thing that you need to think about when
                          building virtual agents from scratch is the
                          opportunity cost. What else could have been built with
                          the time and resources that you dedicated to building
                          a virtual agent? Also, you always need to keep your
                          technical team on standby if you want to add more
                          features or fix bugs.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          Build vs Buy Virtual agent: What’s the verdict?
                        </h5>
                        <p class="font-section-normal-text">
                          Chatbots have revolutionized the way we communicate
                          with brands. They are cost-effective, scalable,
                          flexible, easy to deploy, and can take your
                          communication systems to the next level. The points
                          mentioned above will give you an idea about the
                          prospects of building or buying a virtual agent.
                        </p>
                        <p class="font-section-normal-text">
                          Businesses that have a lot of cash to burn, that is,
                          most enterprises, might be in favour of building a
                          virtual agent from scratch. But it is not as simple as
                          it is touted out to be, even if you have the budget
                          for it. While an enterprise-level chatbot should be
                          able to fully understand your requirements and
                          customize them for you, that’s still possible to get
                          from a SaaS chatbot vendor.
                        </p>
                        <p class="font-section-normal-text">
                          When you are on the lookout to buy a highly-functional
                          and feature-rich chatbot, ask for product tours and
                          detailed demos. Do your due diligence before investing
                          in a virtual agent. Ensure that the product you buy is
                          scalable for the future.
                        </p>
                        <p class="font-section-normal-text">
                          If you don’t want to go through the hassle of building
                          a virtual agent from scratch, Workativ’s solution will
                          take care of it. Chatbots are going to define your
                          customers’ digital experience. Let us help you with
                          the right solution according to your needs.{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk ">
                                How Virtual Agent can help IT service desks? 
                                Virtual Agent powered IT Helpdesk
                              </a>
                            </li>
                            <li>
                              <a href="http://web.workativ.ai/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="http://web.workativ.ai/conversational-ai-platform/ms-teams-bot-service-desk-transformation">
                                {" "}
                                How MS Teams Bot leads to Service Desk
                                Transformation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
